import { Link } from 'gatsby'
import React, { useRef, useState } from 'react'
import Fuse from 'fuse.js'
import useSearchData from './SearchData'

import './search-style.css'

const fuseOptions = {
  shouldSort: true,
  threshold: 0.8,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['node.fields.slug', 'node.frontmatter.title'],
}

let fuse

const getResults = async (data, input) => {
  fuse = new Fuse(data, fuseOptions)
  const results = await fuse.search(input)
  return results
}

function Search() {
  const [results, setResults] = useState([])
  // const [loading, setLoading] = useState(false)
  const debounceRef = useRef()
  const searchData = useSearchData()

  const handleSearch = (e) => {
    const input = e.target.value

    if (debounceRef.current) {
      clearTimeout(debounceRef.current)
    }

    if (input.length === 0) {
      setResults([])
      return
    }

    // setLoading(true)
    debounceRef.current = setTimeout(async () => {
      const found = await getResults(searchData, input)
      setResults(found)
      console.log(found)
      // setLoading(false)
    }, 750)
  }

  return (
    <>
      {/* {loading && <Loader />} */}
      <div className="mt-3 md:mt-0">
        <div className="input-group">
          <input
            type="text"
            name="keyword"
            placeholder="Search.."
            className="textpencarian"
            autocomplete="off"
            onChange={handleSearch}
          />
          <div className="input-group-append">
            <button type="submit" className="tombolsearch">
              <div className="kacapembesar">&#9906;</div>
            </button>
          </div>
        </div>
        <div className="search-results" style={{display: results.length === 0 ? 'none' : 'block' }}>
          {results.slice(0, 10).map((it) => (
            <div className="search-item" key={it.urls}>
              <div className="search-title">
                <Link
                  to={it.item.node.fields.slug}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h4 className="text-xl text-gray-900 leading-tight">
                    {it.item.node.frontmatter.title}
                  </h4>
                </Link>
                <p className="text-sm text-gray-600 leading-normal">
                  {it.item.node.frontmatter.category}
                </p>
              </div>
              <Link to='/blog' className="search-link">
                {it.item.node.frontmatter.date}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default Search