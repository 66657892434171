import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import './global.css'
import useSiteMetadata from './SiteMetadata'
import { withPrefix, Link } from 'gatsby'
import logo from '../img/logo.webp'
import Search from './search'

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata()
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/og-image.jpg`}
        />
      </Helmet>
      <div className="topbar">
        <div className="kontener">
          <div className="row">
            <div className="bagidua">
              <div className="bannerlogo">
                <Link to="/" title="Logo">
                  <img
                    src={logo}
                    alt="Dukcapil Kementerian Dalam Negeri"
                    style={{ maxWidth: '80px' }}
                  />
                  <div style={{ display:'inline-block', verticalAlign:'top', marginLeft:'20px'}}><span style={{ fontSize: '50px' , fontWeight: '700', color: '#dbc3ab' }}>Tatiantel2</span></div>
                </Link>
              </div>
            </div>
            <div className="bagidua">
              <nav className="menuatas">
                <ul>
                  <li>
                    <Search />
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Navbar />
        {children}
      </div>
      <Footer />
      
    </div>
  )
}

export default TemplateWrapper