import React from 'react'
import { Link } from 'gatsby'

import logo from '../img/logo-footer.webp'
import facebook from '../img/social/facebook.svg'
import twitter from '../img/social/twitter.svg'

const Footer = class extends React.Component {
  render() {
    return (

      <footer className="footer has-background-black">
        
        <div className="content">
          <div className="kontener putih">
            <div className="navbar-item">
                      <a className="navbar-item" href="https://pathofbuilding.community/" target="_blank" rel="noopener noreferrer">
                        POB
                      </a>
                      <a className="navbar-item" href="https://poeplanner.com/atlas-tree" target="_blank" rel="noopener noreferrer">
                        Atlas Planner
                      </a>
                      <a className="navbar-item" href="https://filterblade.xyz/" target="_blank" rel="noopener noreferrer">
                        FilterBlade
                      </a>
                      <a className="navbar-item" href="https://www.craftofexile.com/" target="_blank" rel="noopener noreferrer">
                        Craft of Exile
                      </a>
                      <a className="navbar-item" href="https://www.poewiki.net/" target="_blank" rel="noopener noreferrer">
                        POE Wiki
                      </a>
                      <a className="navbar-item" href="https://poedb.tw/" target="_blank" rel="noopener noreferrer">
                        POE DB
                      </a>
                      <a className="navbar-item" href="https://poe-racing.com/" target="_blank" rel="noopener noreferrer">
                        POE Racing
                      </a>
                      <a className="navbar-item" href="https://necropolis.pathoftools.com/" target="_blank" rel="noopener noreferrer">
                        GraveCraft
                      </a>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
