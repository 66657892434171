import { graphql, useStaticQuery } from 'gatsby'

const useSearchData = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query MyQuery {
        allMarkdownRemark(limit: 1000) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
                date(formatString: "DD-MM-YYYY")
                categories {
                    category
                    }
              }
            }
          }
        }
      }
    `
  )
  return allMarkdownRemark.edges
}

export default useSearchData