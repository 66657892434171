import React from 'react'
import { Link } from 'gatsby'


import './nav.css'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  toggleUser = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              userActiveClass: 'is-active',
            })
          : this.setState({
              userActiveClass: '',
            })
      }
    )
  }




  render() {

    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >

          <div className="navbar-brand">
            {/* Hamburger menu */}

            <div
              data-target="userMenu"
              onClick={() => this.toggleUser()}
              style={{ marginLeft: '10px', marginTop: '10px', userSelect: 'none' }}
              className="som"
            >
              👤
            </div>

            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>

          <div className="kontener">
          
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-start has-text-centered">

              <Link className="navbar-item" to="/">
                HOME
              </Link>


              <div className="navbar-item">
                <Link className="anaksub navbar-item" to="/builds/">
                  BUILDS
                </Link>
              </div>

              <div className="navbar-item">
                <Link className="anaksub navbar-item" to="/crafts/">
                  CRAFTS
                </Link>
              </div>

              <div className="navbar-item">
                <Link className="anaksub navbar-item" to="/boss">
                  BOSSES
                </Link>
              </div>
             
              <div className="navbar-item">
                <Link className="anaksub navbar-item" to="/guides">
                  GUIDES
                </Link>
              </div>

              <div className="navbar-item">
                <Link className="anaksub navbar-item" to="/faq/">
                  FAQ
                </Link>
              </div>

                <div className="navbar-item">
                  <a className="anaksub navbar-item" href="https://www.pathofexile.com/forum/view-forum/news" target="_blank" rel="noopener noreferrer">
                  POE NEWS</a>
                </div>
            </div>
          </div>

              <div
                id="userMenu"
                className={`user-menu ${this.state.userActiveClass}`}
              >
                    <div className="som has-text-centered">
                      <a className="navbar-item" href="https://pathofbuilding.community/" target="_blank" rel="noopener noreferrer">
                        POB
                      </a>
                      <a className="navbar-item" href="https://poeplanner.com/atlas-tree" target="_blank" rel="noopener noreferrer">
                        Atlas Planner
                      </a>
                      <a className="navbar-item" href="https://filterblade.xyz/" target="_blank" rel="noopener noreferrer">
                        FilterBlade
                      </a>
                      <a className="navbar-item" href="https://www.craftofexile.com/" target="_blank" rel="noopener noreferrer">
                        Craft of Exile
                      </a>
                      <a className="navbar-item" href="https://www.poewiki.net/" target="_blank" rel="noopener noreferrer">
                        POE Wiki
                      </a>
                      <a className="navbar-item" href="https://poedb.tw/" target="_blank" rel="noopener noreferrer">
                        POE DB
                      </a>
                      <a className="navbar-item" href="https://poe-racing.com/" target="_blank" rel="noopener noreferrer">
                        POE Racing
                      </a>
                    </div>
              </div>

          </div>
                        
      </nav>
    )
  }
}


export default Navbar